////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(224, 224, 224, 1);
}
.bg-thirdColor {
  background-color: rgba(224, 224, 224, 1);
}
.bg-fourthColor {
  background-color: rgba(118, 172, 28, 1);
}
.bg-fifthColor {
  background-color: rgba(166, 187, 132, 1);
}
.bg-sixthColor {
  background-color: rgba(224, 224, 224, 1);
}
.bg-seventhColor {
  background-color: rgba(107, 28, 35, 1);
}
